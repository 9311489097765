import logo from './verifai-logo.png';
import './App.css';
import React, { useEffect }  from "react";
import PageBottom from './PageBottom';
import TopMenu from './TopMenu';
import Facebook from './facebook.png';
import Twitter from './twitter.png';
import LinkeIn from './LinkedIn.png';
import DocumentMeta from 'react-document-meta';
import Github from './github.png';
import Instagram from './instagramico.png';
import TikTok from './tiktok.png';

function Contact() {
  useEffect(() => {
    document.title = "Verif.ai - Contact us!";
  }, []);
  const meta = {
    title: "Verif.ai Contact",
    description: "Contact Verif.ai project",
    canonical: "https://verifai-project.com/contact",
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
  }
  return (
    <div className="App">
      <DocumentMeta {...meta} />
      <TopMenu/>
<hr/>
<div className='LeftFrame'></div>
        <div className='MainFrame'>
            
        <a href='/'><img src={logo} className="App-logo" alt="logo" /></a>
        
        <div className='FrontTextArticle'>
        <h1>Contact</h1>

        In case of any questions and queries, you can contact us via email at <a href="mailto:verif.ai.project@gmail.com">verif.ai.project[at]gmail.com</a>.

        <br/>

        In case, you would like us to consult on deploying solution based on Verif.ai or would like to use the developed solution under different licensing 
        terms then provided by default, please get in touch with us.

        <div id="mc_embed_shell2">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n        #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 300px;}\n        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n           We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
  <div id="mc_embed_signup">
    <form action="https://gmail.us13.list-manage.com/subscribe/post?u=34c8308d0ab65e7b96e0dc5dc&id=330c16a5c6&f_id=00a105e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" >
      <div id="mc_embed_signup_scroll"><h3>Subscribe to our Newsletter</h3>
        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
        <div className="mc-field-group"><label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label><input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required defaultValue="" style={{width:"16em"}}/></div><div className="mc-field-group"><label htmlFor="mce-FNAME">First Name </label><input type="text" name="FNAME" className=" text" id="mce-FNAME" defaultValue="" style={{width:"16em"}}/></div><div className="mc-field-group"><label htmlFor="mce-LNAME">Last Name </label><input type="text" name="LNAME" className=" text" id="mce-LNAME" defaultValue="" style={{width:"16em"}} /></div>
        <div id="mce-responses" className="clear foot">
          <div className="response" id="mce-error-response" style={{display: 'none'}} />
          <div className="response" id="mce-success-response" style={{display: 'none'}} />
        </div>
        <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
          /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
          <input type="text" name="b_34c8308d0ab65e7b96e0dc5dc_330c16a5c6" tabIndex={-1} defaultValue />
        </div>
        <div className="optionalParent">
          <div className="clear foot">
            <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" defaultValue="Subscribe" />
            <p style={{margin: '0px auto'}}><a href="http://eepurl.com/iAxqpI" title="Mailchimp - email marketing made easy and fun"><span style={{display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px'}}><img className="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center'}} /></span></a></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

        <h2>Social media</h2>
        You can follow us on social media: 
        <div className="SocialMedia">
        <a href='https://www.facebook.com/profile.php?id=61550906685787' target='_blank' rel="noreferrer"><img src={Facebook} className='soc_media' alt="Facebook page"/></a>
        <a href='https://twitter.com/verifai_system' target='_blank' rel="noreferrer"><img src={Twitter} className="soc_media" alt="X.com page" /></a>
        <a href='https://www.linkedin.com/company/verif-ai-project' target='_blank' rel="noreferrer"><img src={LinkeIn} className="soc_media" alt="LinkedIn page" /></a>
        <a href='https://www.instagram.com/verifai_system/' target='_blank' rel="noreferrer"><img src={Instagram} className="soc_media2" alt="Instagram page" /></a>
        <a href='https://www.tiktok.com/@verif.ai?_t=8kTqxv5uUp3&_r=1' target='_blank' rel="noreferrer"><img src={TikTok} className="soc_media2" alt="TikTok page" /></a>
        <a href='https://github.com/nikolamilosevic86/verif.ai' target='_blank' rel="noreferrer"><img src={Github} className="soc_media" alt="Github repository" /></a>
        
        </div>


          <br/>
          <br/><br/>
          <br/>



          </div>

        
    </div>
    <div className='RightFrame'></div>
    <PageBottom/>
    </div>
  );
}

export default Contact;
