import logo from './verifai-logo.png';
import './App.css';
import PageBottom from './PageBottom';
import TopMenu from './TopMenu';
import decMaking from './dec-making.jpeg';
import sciAI from './1000058084.jpg';
import AGIPic from './AGI 1.jpg';
import Post from './Post.js'
import React, { useEffect }  from "react";
import DocumentMeta from 'react-document-meta';
import scepticRobot from './Sceptic_Robot.jpeg';
import registration_screen from './registration.jpeg';
import generating_answer from './generating_answer.jpeg';
import generating_answer_verif from './generating_answers_verification.jpeg';
import verifAI_app_cr from './VerifAI_App_cr.png';

var BlogPosts = [
  {
    "id":"what-does-the-NEW-verifAI-APP-Offer",
    "Title":"What Does the NEW VerifAI APP Offer and How Can I Use It Effectively?",
    "Body":<div>
        <p>In the world of biomedicine, accuracy and reliability of information are of utmost importance. The VerifAI system, an advanced AI tool for natural language processing with a focus on biomedical data, promises to revolutionize the way researchers, doctors, and students access and utilize expert information. What makes VerifAI ideal for use in this critical field is that it is designed not to hallucinate and to provide referenced answers. In this blog, we explore how you can use VerifAI. Follow the steps outlined and take full advantage of everything VerifAI offers to enhance your research and work!</p>
        <img src={verifAI_app_cr} alt="VerifAI app" className='blog'></img>
        <h3>Step 1: Registration and Account Creation</h3>
        <p>The first step in using VerifAI is to sign up on our platform. On the VerifAI homepage, you will see the button ‘Join VerifAI Now!’ which is a shortcut to the VerifAI application. Visit it and quickly and easily create your account. You will need to enter basic information such as your full name, username, email address, and password. If you have entered all the data correctly, you will see a notification that your account has been successfully created. Click ‘OK’, log in, and you can start using the application!</p>
        <img src={registration_screen} alt="Registration screen of VerifAI app" className='blog'></img>
        <h3>Step 2: Getting Familiar with the Interface</h3>
        <p>After logging in, explore VerifAI's minimalist and practical user interface. At the top of the screen, you will find your username and navigation tabs for quick access to various application functions that you may need.</p>
        <h3>Step 3: Submitting Inputes and Generating Answers</h3>
        <p>VerifAI allows you to submit specific queries related to biomedical topics. For example, if you want to learn more about the latest research in the field of immunotherapy, simply enter the query in the search bar. Or, for instance, you can investigate the effects of a new drug on breast cancer treatment. By submitting a query in VerifAI, you receive answers that include the latest research, clinical studies, and meta-analyses. This way, you not only save time but also ensure that the information you use is accurate and evidence-based. After submitting a query, VerifAI will analyze the available data in the next few seconds and work on providing accurate and referenced answers from reliable sources.</p>
        <img src={generating_answer} alt="Generating answer" className='blog'></img>
        <h3>Step 4: Interpreting VerifAI Output</h3>
        <p>As part of the VerifAI output, you will receive not only an answer but also lists of scientific sources from the PubMed database that VerifAI used to prepare a verifiable and precise answer for you! If you click on any of them, VerifAI will take you to the specific PubMed article where it found the statement related to your question. Then follows the specific answer with references. You will notice that most statements are written in green, with references in the form of links on their right side. When you hover over a statement, an automatic explanation will appear above, indicating that the green color means confirmation that the statement is in the article to which the reference points (thanks to the double verification method that VerifAI uses!). Additionally, you will be provided with a quote, i.e., the original paragraph/part of the paragraph from which the statement was derived. This allows users to confirm the accuracy of the data on the spot before including it in their work!</p>
        <img src={generating_answer_verif} alt="Generating answer" className='blog'></img>
        <p>Other statements may be written in orange. In the same way as before, you will determine the meaning of the color. Orange means that it is likely that the statement is in the document to which the reference points. However, this time VerifAI was not able to display part of the given PubMed article in the form of a quote. It may happen that the abstract provided by VerifAI at the bottom of the page is written in gray. There is a possibility that the statements it contains are accurate, but since VerifAI did not find adequate evidence, it advises you in this way to additionally verify their accuracy independently.</p>
        <h3>Conclusion</h3>
        <p>We believe that VerifAI represents a decent step forward in the use of artificial intelligence for processing and analyzing biomedical data. Its ability to provide referenced answers without hallucinations makes it an ideal tool for professionals in the field of biomedicine. However, if you are a layperson in this field, it does not mean that VerifAI is not for you! On the contrary, VerifAI is a reliable system that we warmly recommend as a consultant when you want to quickly find an explanation for a health-related question or concern you have.</p>
        <p><strong>Try VerifAI today and experience the power of accurate and referenced biomedical information!</strong></p>
    </div>,
    "Date":"23.07.2024",
    "Author":"Angela Pupovac"

  },
  {
    "id":"VerifAI-project-Open-source-biomedical-question-answering-with-verified-answers",
    "Title": "VerifAI project: Open source biomedical question answering with verified answers",
    "Body":<div>
    <h3>Experiences from building LLM-based (Mistral 7B) biomedical question-answering system on top of Qdrant and OpenSearch indices with hallucination detection method</h3>
    <p>Last September (2023), we embarked on the development of the <a href="https://verifai-project.com" target="_blank">VerifAI project</a>, after receiving funding from the <a href="https://www.ngisearch.eu" target="_blank">NGI Search</a> funding scheme of Horizon Europe.</p>
    <p>The idea of the project was to create a generative search engine for the biomedical domain, based on vetted documents (therefore we used a repository of biomedical journal publications called <a href="https://pubmed.ncbi.nlm.nih.gov" target="_blank">PubMed</a>), with an additional model that would verify the generated answer, by comparing the referenced article and generated claim.</p>
    <p>While there are projects and products, such as Elicit or Perplexity that do partially RAG (Retrieval-Augmented Generation) and can answer and reference documents for biomedical questions, there are a few factors that differentiate our project. Firstly, we focus at the moment on the biomedical documents. Secondly, as this is a funded project by the EU, we commit to open-source everything that we have created, from source code, models, model adapters, datasets, everything. Thirdly, no other product, that is available at the moment, does a posteriori verification of the generated answer, but they usually just rely on fairly simple RAG, which reduces hallucinations but does not remove them completely. One of the main aims of the project is to address the issue of so-called hallucinations. Hallucinations in Large Language Models (LLMs) refer to instances where the model generates text that is plausible-sounding but factually incorrect, misleading, or nonsensical. In this regard, the project adds to the live system's unique value.</p>
    <p>The project has been shared under the <a href="https://www.gnu.org/licenses/agpl-3.0.en.html" target="_blank">AGPLv3 license</a>.</p>
    <h3>Overall method</h3>
    <p>The overall methodology that we have applied can be seen in the following figure</p>
     <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*1g3ejM65p5dXc2wmdMvG9w.png"/>
     <p>When a user asks a question, the user query is transformed into a query, and the information retrieval engine is asked for the most relevant biomedical abstracts, indexed in PubMed, for the given question. In order to obtain the most relevant documents, we have created both a lexical index, based on <a href="https://opensearch.org/" target="_blank">OpenSearch</a>, and a vector/semantic search based on <a href="https://qdrant.tech/" target="_blank">Qdrant</a>. Namely, lexical search is great at retrieving relevant documents containing exact terms as the query, while semantic search helps us to search semantic space and retrieve documents that mean the same things, but may phrase it differently. The retrieved scores are normalized and a combination of documents from these two indices are retrieved (hybrid search). The top documents from the hybrid search, with questions, are passed into the context of a model for answer generation. In our case, we have fine-tuned the Mistral 7B-instruct model, using QLoRA, because this allowed us to host a fairly small well-performing model on a fairly cheap cloud instance (containing NVidia Tesla T4 GPU with 16GB of GPU RAM). After the answer is generated, the answer is parsed into sentences and references that support these sentences and passed to the separate model that checks whether the generated claim is supported by the content of the referenced abstract. This model classifies claims into supported, no-evidence, and contradicting classes. Finally, the answer, with highlighted claims that may not be fully supported by the abstracts is presented to the used.</p>
 <p>Therefore, a system has 3 components&hairsp;—&hairsp;information retrieval, answer generation, and answer verification. In the following sections, we will describe in more detail, each of these sections.&nbsp;</p>
 <h3>Information retrieval</h3>
 <p>From the start of the project, we aimed at building a hybrid search, combining semantic and lexical search. The initial idea was to create it using a single software, however, that turned out not so easy, especially for the index of the PubMed size. PubMed contains about 35 million documents, however, not all contain full abstracts. There are old documents, from the 1940s and 1950s that may not have abstracts, but as well some guide documents and similar that have only titles. We have indexed only the documents containing full abstracts and ended up with about 25 million documents. PubMed unpacked is about 120GB in size.&nbsp;</p>
 <p>It is not problematic to create a well-performing index for lexical search in OpenSearch. This worked pretty much out of the box. We have indexed titles, and abstract texts, and added some metadata for filtering, like year of publication, authors, and journal. OpenSearch supports <a href="https://engineering.fb.com/2017/03/29/data-infrastructure/faiss-a-library-for-efficient-similarity-search/"  target="_blank">FAISS</a> as a vector store. So we tried to index our data with FAISS, but this was not possible, as the index was too large and we were running out of memory (we had a 64GB cloud instance for index). The indexing was done using MSMarco fine-tuned model based on DistilBERT (<a href="https://huggingface.co/sentence-transformers/msmarco-distilbert-base-tas-b"  target="_blank">sentence-transformers/msmarco-distilbert-base-tas-b</a>). Since we learned that FAISS only supported the in-memory index, we needed to find another solution that would be able to store a part of the index on the hard drive. The solution was found in the Qdrant database, as it supports in-memory<a href="https://qdrant.tech/documentation/concepts/storage/#configuring-memmap-storage" target="_blank"> mapping and on-disk storage of part of the index</a>.&nbsp;</p>
 <p>Another issue that appeared while creating the index was that once we did memory mapping and created the whole PubMed index, the query would be executed for a long time (almost 30 seconds). The problem was that calculations of dot products in 32-bit precision were taking a while for the index having 25 million documents (and potentially loading parts of the index from HDD). Therefore, we have made a search using only 8-bit precision, and we have reduced the time needed from about 30 seconds to less than a half second.&nbsp;</p>
 <p>The lexical index contained whole abstracts, however, for the semantic index, documents needed to be split, because the transformer model we used for building the semantic index could swallow 512 tokens. Therefore the documents were split on full stop before the 512th token, and the same happened on every next 512 tokens.&nbsp;</p>
 <p>In order to create a combination of semantic and lexical search, we have normalized the outputs of the queries, by dividing all scores returned either from Qdrant or OpenSearch by the top score returned for the given document store. In that way, we have obtained two numbers, one for semantic and the other for lexical search, in the range between 0–1. Then we tested the precision of retrieved most relevant documents in the top retrieved documents using the <a href="https://link.springer.com/content/pdf/10.1186/s12859-015-0564-6.pdf"  target="_blank">BioASQ dataset</a>. The results can be seen in the table below.&nbsp;</p>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*uTaFRuCgJ4NX_jxvO5WaPQ.png"/>
 <p data-testid="editorParagraphText" name="8868" class="graf graf--p graf-after--figure">We have done some re-ranking experiments using full precision, so you can see more details in the paper. But this has not been used in the application at the end. The overall conclusion was that lexical search does a pretty good job, and there is some contribution of semantic search, with the best performance obtained at weights of 0.7 for lexical search, and 0.3 for semantic.</p>
 <p>Finally, we have built a query processing, where for lexical querying stopwords were excluded from the query, and search was performed in the lexical index, and similarity was calculated for the semantic index. Values for documents from both semantic and lexical indexes were normalized and summed up, and the top 10 documents were retrieved.&nbsp;</p>
 <h3>Referenced answer generation</h3>
 <p>Once, the top 10 documents were retrieved, we could pass these documents to a generative model for referenced answer generation. We have tested several models. This can be done well with GPT4-Turbo models, and most of commercially available platforms would use GPT4 or Claude models. However, we wanted to create an open-source variant, where we do not depend on commercial models, having smaller and more efficient models while having also performance close to the performance of commercial models. Therefore, we have tested things with Mistral 7B instruct in both zero-shot regime and fine-tuned using 4bit QLora.</p>
 <p>In order to fine-tune Mistral, we needed to create a dataset for referenced question answering using PubMed. We created a dataset by randomly selecting questions from the PubMedQA dataset, then retrieved the top 10 relevant documents and used GPT-4 Turbo for referenced answer generation. We have called this dataset the <a href="https://huggingface.co/datasets/BojanaBas/PQAref"  target="_blank">PQAref dataset and published it on HuggingFace</a>. Each sample in this dataset contains a question, a set of 10 documents, and a generated answer with referenced documents (based on 10 passed in context).&nbsp;</p>
 <p>Using this dataset, we have created a QLoRA adapter for Mistral-7B-instruct. This was trained on the <a href="https://www.ai.gov.rs/"  target="_blank">Serbian National AI platform</a> in the National Data Center of Serbia, using Nvidia A100 GPU. The training lasted around 32 hours.&nbsp;</p>
 <p>We have performed an evaluation comparing Mistral 7B instruct v1, and Mistral 7B instruct v2, with and without QLoRA fine-tuning (so without is zero-shot, based only on instruction, while with QLoRA we could save some tokens as instruction was not necessary for the prompt, as fine-tuning would make model do what is needed), and we compared it with GPT-4 Turbo (with prompt: “Answer the question using relevant abstracts provided, up to 300 words. Reference the statements with the provided abstract_id in brackets next to the statement.”). Several evaluations about the number of referenced documents and whether the referenced documents are relevant have been done. These results can be seen in the tables below.</p>
 <img  className='blog' src="https://cdn-images-1.medium.com/max/1600/1*1G_jbg96VYHHX3RbEJC0Fw.png"/>
 <p>From this table, it can be concluded that Mistral, especially the first version in zero-shot (0-M1), does not really often reference context documents, even though it was requested in the prompt. On the other hand, the second version showed much better performance, but it was far from GPT4-Turbo or fine-tuned Mistrals 7B. Fine-tuned mistrals tended to cite more documents, even if the answer could be found in one of the documents, and added some additional information compared to GPT4-Turbo.</p>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*3fmPqNtEt37NyyIdXCIqMQ.png"/>
 <p>As can be seen from the second table, GPT4-Turbo missed relevant references only once in the whole test set, while Mistral 7B-instruct v2 with fine-tuning missed a bit more, but showed still comparable performance, given much smaller model size.&nbsp;</p>
 <p>We have also looked at several answers manually, to make sure the answers make sense. At the end, in the app, we are using Mistral 7B instruct v2 with a fine-tuned QLoRA adapter.&nbsp;</p>
 <h3>Answer verification</h3>
 <p>The final part of the system is answer verification. For answer verification, we have developed several features, however, the main one is a system that verifies whether the generated claim is based on the abstract that was referenced. We have fine-tuned several models on the <a href="https://allenai.org/data/scifact"  target="_blank">SciFact dataset </a>from the Allen Institute for AI with several BERT and Roberta-based models.&nbsp;</p>
 <p>In order to model input, we have parsed the answer to find sentences and related references. We have found that the first and last sentence in our system are often introduction or conclusion sentences, and may not be referenced. All other sentences should have a reference. If a sentence contained a reference, it was based on that PubMed document. If the sentence does not contain a reference, but the sentence before and after are referenced, we calculate the dot product of the embeddings of that sentence and sentences in 2 abstracts. Whichever abstract contains the sentence with the highest dot product is treated as the abstract that the sentence was based on.&nbsp;</p>
 <p>Once the answer is parsed and we have found all the abstracts the claims are based on, we pass it to the fine-tuned model. The input to the model was engineered in the following way</p>
 <p>For deBERT-a models:</p>
 <blockquote>[CLS]claim[SEP]evidence[SEP]</blockquote>
 <p>For Roberta-based models:</p>
 <blockquote>&lt;s&gt;claim&lt;/s&gt;&lt;/s&gt;evidence&lt;/s&gt;</blockquote><p data-testid="editorParagraphText" name="96f4" class="graf graf--p graf-after--blockquote">Where claim is generated claim from the generative component, and evidence is the text of concatenated title and abstract from referenced PubMed document.&nbsp;</p>
 <p>We have evaluated the performance of our fine-tuned models and obtained the following results:&nbsp;</p>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*eeAdmTh0lj7BjO4dCx98VA.png"/>
 <p data-testid="editorParagraphText" name="937d" class="graf graf--p graf-after--figure">Often, when the models are fine-tuned on the same dataset as they are tested, the results are good. Therefore, we wanted to test it also on out-of-domain data. So we have selected the <a href="https://github.com/sarrouti/HealthVer"  target="_blank">HealthVer dataset</a>, which is also in the healthcare domain used for claim verification. The results were the following:&nbsp;</p>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*_N9MbQ77tH1ewS65JjxmVw.png"/>
 <p data-testid="editorParagraphText" name="3366" class="graf graf--p graf-after--figure">We also evaluated the SciFact label prediction task using the GPT-4 model (with a prompt “Critically asses whether the statement is supported, contradicted or there is no evidence for the statement in the given abstract. Output SUPPORT if the statement is supported by the abstract. Output CONTRADICT if the statement is in contradiction with the abstract and output NO EVIDENCE if there is no evidence for the statement in the abstract.” ), resulting in a precision of 0.81, a recall of 0.80, and an F-1 score of 0.79. Therefore, our model has better performance and due to the much lower number of parameters was more efficient.&nbsp;</p>
 <p>On top of the verification using this model, we have also calculated the closest sentence from the abstract using dot product similarity (using the same MSMarco model we use for semantic search). We visualize the closest sentence to the generated one on the user interface by hovering over a sentence.&nbsp;</p>
 <h3>User interface</h3>
 <p>We have developed a user interface, to which users can register, log in, and ask questions, where they will get referenced answers, links to PubMed, and verification by described posterior model. Here are a few screenshots of the user interface:</p>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*WzueioyBwfYlagnhnehGIg.png"/>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*FwzI9FAKO-Mojuk4rLuD2Q.png"/>
 <h3>Conclusion</h3>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/0*Xh3CEZQ5FzYXS77C"/>
 <p>We are here to present our experience from building the VerifAI project, which is the biomedical generative question-answering engine with verifiable answers. We are open-sourcing whole code and models and we are opening the application, at least temporarily (depends on the budget for how long, and how and whether we can find a sustainable solution for hosting). In the next section, you can find the links to the application, website, code, and models.&nbsp;</p>
 <p>The application is a result of the work of multiple people (see under Team section) and almost a year of research and development. We are happy and proud to present it now to a wider public and hope that people will enjoy it, and as well contribute to it, to make it more sustainable and better in the future.&nbsp;</p>
 <h3>Cite our&nbsp;papers</h3>
 <p>If you use any of the methodology, models, datasets, or are mentioning this project in your paper’s background section, please cite some of the following papers:</p>
 <ul class="postList">
 <li><a href="https://arxiv.org/pdf/2402.18589"  target="_blank">Košprdić, M., Ljajić, A., Bašaragin, B., Medvecki, D., &amp; Milošević, N. “Verif. ai: Towards an Open-Source Scientific Generative Question-Answering System with Referenced and Verifiable Answers.” The Sixteenth International Conference on Evolving Internet INTERNET 2024 (2024).</a></li>
 <li><a href="https://arxiv.org/abs/2407.05015"  target="_blank">Bojana Bašaragin, Adela Ljajić, Darija Medvecki, Lorenzo Cassano, Miloš Košprdić, Nikola Milošević “How do you know that? Teaching Generative Language Models to Reference Answers to Biomedical Questions”, The 23rd BioNLP Workshop 2024, Colocated with ACL 2024</a></li></ul>
 <h3 >Availability</h3>
 <p>The application can be accessed and tried at <a href="https://verifai-project.com/"  target="_blank">https://verifai-project.com/</a> or <a href="https://app.verifai-project.com/" target="_blank">https://app.verifai-project.com/</a>. Users can here register, and ask questions to try our platform.</p>
 <p>The code of the project is available at GitHub at <a href="https://github.com/nikolamilosevic86/verifAI"  target="_blank">https://github.com/nikolamilosevic86/verif.ai</a>. The QLoRA adapters for Mistra 7B instruct can be found on HuggingFace at <a href="https://huggingface.co/BojanaBas/Mistral-7B-Instruct-v0.2-pqa-10" target="_blank">https://huggingface.co/BojanaBas/Mistral-7B-Instruct-v0.2-pqa-10</a> and <a href="https://huggingface.co/BojanaBas/Mistral-7B-Instruct-v0.1-pqa-10" target="_blank">https://huggingface.co/BojanaBas/Mistral-7B-Instruct-v0.1-pqa-10</a>. A generated dataset for fine-tuning the generative component can be found also on HuggingFace at <a href="https://huggingface.co/datasets/BojanaBas/PQAref"  target="_blank">https://huggingface.co/datasets/BojanaBas/PQAref</a>. A model for answer verification can be found at <a href="https://huggingface.co/MilosKosRad/TextualEntailment_DeBERTa_preprocessedSciFACT" target="_blank">https://huggingface.co/MilosKosRad/TextualEntailment_DeBERTa_preprocessedSciFACT</a>.</p>
 <h3>Team</h3>
 <p>The VerifAI project was developed as a collaborative project between Bayer Pharma R&amp;D and the Institute for Artificial Intelligence Research and Development of Serbia, funded by the NGI Search project under grant agreement No 101069364. The people involved are <a href="https://www.linkedin.com/in/nikolamilosevic1986/" target="_blank">Nikola Milosevic</a>, <a href="https://www.linkedin.com/in/lorenzo-cassano-360b631b1/" target="_blank">Lorenzo Cassano</a>, <a href="https://www.linkedin.com/in/bojana-basaragin/" target="_blank">Bojana Bašaragin</a>, <a href="https://www.linkedin.com/in/milo%C5%A1-ko%C5%A1prdi%C4%87/"  target="_blank">Miloš Košprdić</a>, <a href="https://www.linkedin.com/in/adelacrnisanin/"  target="_blank">Adela Ljajić</a> and <a href=" https://www.linkedin.com/in/darija-medvecki-b27468ab/" target="_blank">Darija Medvecki</a>.</p>
 <img className='blog' src="https://cdn-images-1.medium.com/max/1600/1*UqR82MmSorm4C2nwuV-tkg.jpeg"/>
 </div>,
      "Date":"16.07.2024",
      "Author":"Nikola Milosevic"
  },
  {
    "id":"the-european-union-must-keep-funding-free-software",
    "Title":"The European Union must keep funding free software",
    "Body":<div>
      <p>Since 2020, Next Generation Internet (NGI) programmes, part of European Commission's Horizon programme, fund free software in Europe using a cascade funding mechanism (see for example NLnet's calls). This year, according to the Horizon Europe working draft detailing funding programmes for 2025, we notice that Next Generation Internet is not mentioned any more as part of Cluster 4.</p>
      <p>NGI programmes have shown their strength and importance to support the European software infrastructure, as a generic funding instrument to fund digital commons and ensure their long-term sustainability. We find this transformation incomprehensible, moreover when NGI has proven efficient and ecomomical to support free software as a whole, from the smallest to the most established initiatives. This ecosystem diversity backs the strength of European technological innovation, and maintaining the NGI initiative to provide structural support to software projects at the heart of worldwide innovation is key to enforce the sovereignty of a European infrastructure.</p>
      <p>Contrary to common perception, technical innovations often originate from European rather than North American programming communities, and are mostly initiated by small-scaled organizations.</p>
      <p>Previous Cluster 4 allocated 27 millions euros to:</p>
      <ul>
        <li>"Human centric Internet aligned with values and principles commonly shared in Europe" ;</li>
        <li>"A flourishing internet, based on common building blocks created within NGI, that enables better control of our digital life" ;</li>
        <li>"A structured eco-system of talented contributors driving the creation of new internet commons and the evolution of existing internet commons" .</li>
      </ul>
      <p>In the name of these challenges, more than 500 projects received NGI funding in the first 5 years, backed by 18 organisations managing these European funding consortia.</p>
      <p>NGI contributes to a vast ecosystem, as most of its budget is allocated to fund third parties by the means of open calls, to structure commons that cover the whole Internet scope - from hardware to application, operating systems, digital identities or data traffic supervision. This third-party funding is not renewed in the current program, leaving many projects short on resources for research and innovation in Europe.</p>
      <p>Moreover, NGI allows exchanges and collaborations across all the Euro zone countries as well as "widening countries"¹, currently both a success and and an ongoing progress, likewise the Erasmus programme before us. NGI also contributes to opening and supporting longer relationships than strict project funding does. It encourages to implement projects funded as pilots, backing collaboration, identification and reuse of common elements across projects, interoperability in identification systems and beyond, and setting up development models that mix diverse scales and types of European funding schemes.</p>
      <p>While the USA, China or Russia deploy huge public and private resources to develop software and infrastructure that massively capture private consumer data, the EU can't afford this renunciation.</p>
      <p>Free and open source software, as supported by NGI since 2020, is by design the opposite of potential vectors for foreign interference. It lets us keep our data local and favors a community-wide economy and know-how, while allowing an international collaboration.</p>
      <p>This is all the more essential in the current geopolitical context: the challenge of technological sovereignty is central, and free software allows to address it while acting for peace and sovereignty in the digital world as a whole.</p>
    </div>,
     "Date":"16.07.2024",
     "Author":"Nikola Milosevic"
  },
  {
    "id":"ailiteracy-debunking-misconceptions",
    "Title": "AI Literacy: Debunking Pre- and Misconceptions",
    "Body": <div>
    <p>Within society, we still encounter surprisingly large gaps in knowledge regarding AI, owing to insufficient information or misinformation. Some false conceptions may not have significant consequences and will be debunked along the way through more frequent interaction between people and AI, while others are eventually doubly harmful in the sense that they are so far removed from true state of affairs that they can instill genuine fear and anxiety in individuals, which over time can lead to undesirable effects on even the AI industry itself, resulting in its stagnation due to needlessly hindering the acceptance and utilization of its advancements.</p>
    <p>In the meantime, while such background inaccurate notions spread, AI is developing to be best aligned with us and our needs - and it's worth reexamining acquired concerns and making a real effort to understand it properly. Thus, the aim of this essay is to portray a realistic picture of what AI is and what its 'intentions' are, by uncovering the accompanying 'human nature' factors that, alongside inadequate knowledge, contribute to the emergence of the 'loudest' myths about it, and providing fact-based explanations as to why they really have no place in actuality.</p>
    <img src={scepticRobot} alt="Image of scientist and AI" className='blog'></img>
    <h3>Conceptions Based on Distrust/Fear</h3>
    <h4>'AI will render human expertise obsolete.'</h4>
    <p>Taking into consideration the extent to which this concern has grown, perhaps above all else, we should address it. The first thing that is important to emphasize is that the goal of engaging AI in various business spheres, and indeed the goal of technological advancement, has never been to 'replace' humans in any way. Yes, this may seem easily achievable when we consider the benefits that employers can derive from such a thing, and that AI is becoming increasingly proficient in many aspects. And yes, we are beginning to encounter some examples of individuals who have lost half or all their working hours. However, despite all this, you can rest assured that there is not much reason for panic.</p>
    <p>What history remembers are the three industrial revolutions that brought about major changes regarding professions. Currently, the fourth is underway, in which AI plays a significant role. A chronological overview of these significant events suggests that what happened during previous rapid increases in technology usage was not the disappearance of jobs as such, but rather the elimination of old positions and the emergence of new, updated ones after the market stabilizes. Machines took over physical efforts (and mostly everything we least like to do), leaving people with more comfortable responsibilities. The only consequence of these changes is better overall results, both in terms of quantity and quality. Guided by this insight, it is reasonable to assume that the same will happen again this time, and we should start preparing ourselves, not for our work to become redundant, but for it to be aligned with the work that this new intelligent business partner will perform alongside us.</p>

    <h4>'AI is necessarily biased.'</h4>
    <p>This is as equally untrue as considering AI to be completely objective. The key to understanding this aspect of the topic is that existing AI systems are objective only to the extent that the data they are trained on, the algorithms they use, and the ways in which they are used in practice allow them to be. Every recorded biased moment in AI stems from the fact that all AI systems perpetuate and amplify existing biases in the data they are trained on - because that is the only way they can function. (Bewersdorff et al. 2023: 9) The solution lies in selecting and using high-quality, verified tools that, when used together, are capable of producing the desired output.</p>
    <p>Furthermore, upon deeper reflection, it may not always be desirable for AI results to possess the quality of complete objectivity. While the task is certainly to make AI less prone to unwarranted exclusion or elevation of certain groups of people, beliefs, etc., it remains to be seen to what extent it will be possible to deprive AI of the ability to individualize while still maintaining the element of personalized service (an experience that users definitely enjoy). What seems like a reasonable compromise is for personal use systems to contain additional components that enable them to recognize differences in personal preferences and needs (e.g., a smart OS personal assistant), while others would ideally be devoid of this and perceive circumstances in a more generalized manner (e.g., a bank's chatbot).</p> 
    <h4>'AI represents a mysterious problem space.'</h4>
    <p>This conclusion might be easy to draw due to the significant complexity of AI at first glance and its rapidly growing capabilities, but it is just a sign of a deep misunderstanding of it. (Bewersdorff et al. 2023: 7) When we start learning about segments and processes within AI systems (as is the case with any other completely new subject), everything slowly ceases to be enigmatic once and for all. In the process, we will also realize that, while it is true that those systems have their own mechanisms (and that's precisely what enables them to surpass us and thus strengthen us in certain fields of operation), it is inevitable that humans, as their creators, have exerted a crucial influence on them, which from the outset ensures a good level of our understanding of how they operate, and thus the ability to control them.</p>
    <p>If we are convinced that we're dealing with something that, for whatever reason, we can't fully understand, at the very least we'll be skeptical and persistently avoid it. In that case, when it comes to AI, we may miss out on utilizing various useful tools that are offered. However, it can also lead us to automatically characterize it as unpredictable and potentially dangerous, believing that we are unable to have a clear insight and sufficient control over its goals and powers. Such an understanding is particularly problematic because it implies a sense of fear that is not directed towards any concrete (real) risk but rather is perceived as a constant invisible threat, so we can start feeling unease frequently hearing about all the new AI inventions, and also because it leaves too much room for projecting various 'possible' ending scenarios, thus prolonging and strengthening false alarm.</p>
    <p>It's important to know that these apprehensions are unfounded because AI is very far from breaking away from human authority in any sense, or any kind of independence and autonomy (if it ever could even reach it at all).</p>

    <h3>Conceptions Based on Underestimation</h3>
    <h4>'AI is superfluous to me.'</h4>
    <p>Besides the fact that it's already complicated to bypass AI technologies at this moment, given that we are already interacting with them without even noticing (e.g., every suggested word on Google search engine is thanks to AI), the future will make their use inevitable for everyone. It's likely that AI will relatively soon become responsible for all payments, entering one's office, etc., thanks to its 'magical' ability to make everything more efficient and simpler. Thus, someone's further rejection of these innovations implies frequent difficulties in performing their routine activities.</p>
    <p>The reasons behind someone's stance that they don't want to have anything to do with AI are usually related to the impression that its use is actually a kind of hindrance. This can mean that they personally don't feel the need to change their existing habits, or that they are uncomfortable relying on AI insights due to doubts about its competence, or perhaps that they feel a sense of guilt when using AI tools, considering it unjustifiably leveraging others' abilities for their own projects. It is true that AI is rapidly changing the way we operate, and we are expected to keep up with it. Also, AI fallibility is a real problem that must be addressed, as trust is not given for free. It can be difficult, as well, to strike the right balance, and sometimes it is necessary to limit AI use to ensure the authenticity of our results. However, it is still highly advisable to keep in mind that one of the main purposes of AI development is and will be to facilitate our daily and professional tasks, so, in the long run, it is much more beneficial than harmful for us to gradually become familiar with it so that we can stay abreast of the improvements it brings and know how to enjoy them in the best possible way for us.</p>
     <h4>'AI is only a weak competitor to humans.'</h4>
     <p>In the given context, creativity is usually the first target. (Bewersdorff et al. 2023: 7) If we hold this conception, we see AI mainly as a tool for automating repetitive tasks and believe it should not have the right to participate in art because it lacks the necessary capacity to contribute on an equal footing with humans. There are numerous arguments against attributing this trait to AI, and our advocacy for it may have a point, but not necessarily in every way - this debate can rightfully be lengthy and still end without a uniform conclusion. Whatever the case may be, in that position, we certainly miss seeing and understanding the important role that it currently has in shaping our cultural landscape.</p>
     <p>This critique can also apply to general AI capabilities, where it is argued that AI is automatically inferior to humans simply because it has little in common with (our) unique natural type of intelligence. (Bewersdorff et al. 2023: 7) It is not disputed that AI systems have quite distinct strategies from human brain, but this is not a legitimate reason to negatively compare them to us - rather, it is about acknowledging that we are clever in different ways. It should be recognized that in some areas, AI can solve problems and make decisions better than humans. Similarly, it is okay that it still has its own limitations regarding tasks that are easy for us, such as understanding context and recognizing emotions.</p>
     <p>Building on this, the lack of emotions is another target if we consider that absence of them means something cannot express empathy, give good advice, or just be morally upright. (Bewersdorff et al. 2023: 7) Once again, today’s AI is not a self-regulating mechanism; it only adopts the values and habits that we humans instill in it. Therefore, it logically follows that proper training will lead to benevolent AI in this regard.</p>
     <h4>'AI is (that) one thing.'</h4>
     <p>Limited understanding of the various types of AI may be the naivest falsehood regarding it. This viewpoint can be formed when we lack acquaintance with a broader range of AI resources and rather rely on just one.</p>
     <p>However, it is fundamentally flawed to perceive AI as a singular entity. AI manifests in numerous forms and serves diverse purposes.AI is not confined to physical embodiments like robots or VR equipment; many AI technologies exist purely in software. (Bewersdorff et al. 2023: 6) Also, it is not restricted to content generation; among other tasks. AI can aid in discovering new compounds.</p>
     <p>Understanding the spectrum of AI, besides its good for opening new possibilities for our own use, is a key component for correctly comprehending its ability to drive changes in society. This is important not only so we can see how it will contribute to us but also to recognize probable challenges that may arise with it in the future, and to timely contemplate their solutions.</p>
     <h3>Conclusion</h3>
     <p>We have observed that the prevailing pre- and misconceptions surrounding AI stem from an inconvenient combination of a lack of adequate knowledge and the typical feelings we tend to experience when facing something predominantly unfamiliar. Nevertheless, regardless of anyone's previous resistance, by opening a bit more to this new reality, seeking answers to their questions, and experimenting with innovations, there is a great chance that they will, at some point, catch themselves being much more enthusiastic, realizing that they are actually very privileged to be living in this exact era.</p>
     <h3>References</h3>
     <p>Bewersdorff, Arne, Yhai, Xiaoming, Roberts, Jessica & Claudia Nerdel (2023). „Myths, mis- and preconceptions of artificial intelligence: A review of the literature“, Computers and Education: Artificial Intelligence: 1-9.</p>
     </div>,
     "Date":"09.04.2024",
     "Author":"Angela Pupovac"
  },
  {
    "id":"presentation-internet2024",
    "Title": "Verif.ai presented at The Sixteenth International Conference on Evolving Internet (INTERNET 2024)",
    "Body": <div><p>Dear Verif.ai Community,</p>
    <p>We presented the progress and current stage of the Verif.ai project at the sixteenth International Conference on the Evolving Internet 2024.</p>
    <p>The paper titled "Verif.ai: Towards an Open-Source Scientific Generative Question-Answering System with Referenced and Verifiable Answers" has been published in the conference proceedings.</p>
    <p>It is our pleasure to provide you with the materials used at the conference. Explore them and learn about the mechanisms behind the innovative VerifAI system!</p>
    <p>Links:</p>
    <ul>
          <li><a href="https://www.iaria.org/conferences2024/INTERNET24.html" target="_blank">The Sixteenth International Conference on Evolving Internet (INTERNET 2024)</a></li>
          <li><a href="https://www.slideshare.net/slideshows/verifai-project-presentation-internet2024-conference/266753001" target="_blank">Verif_ai Project Presentation - INTERNET2024 Conference | PPT (slideshare.net)</a></li>
          <li><a href="https://arxiv.org/pdf/2402.18589.pdf" target="_blank">Verif-ai paper</a></li>
    </ul>
    <p>Stay tuned for more updates on our progress!</p>
   

     </div>,
     "Date":"15.03.2024",
     "Author":"Angela Pupovac"
  },
  {
    "id":"evolution-ai",
    "Title": "Genesis and Evolution of AI'",
    "Body": <div><p>If we wish to understand how and when AI began, we will uncover a long and highly interesting process. When did the belief in constructing functional mechanical entities resembling humans emerge? What inspired the development of many intelligent technologies we use today? The initial ideas can be traced back to various ancient philosophical discussions that explored the possibility of artificial humans. There was no shortage of imagination, and quality arguments were formed, but there wasn't much knowledge about where to start in practice. Fortunately, certain innovators appeared. For example, Leonardo da Vinci decided to put some of the speculations into action and successfully constructed a robot dressed in armor, called Leonardo's mechanical knight, capable of making several human-like motions, and it was declared fully functional. These and similar initial discoveries instilled new hope in creating an 'electronic brain' and empowered certain scientists for the first serious attempts. But, did everything go smoothly, or were there any issues? Researching the past reveals that the development of AI has had significant ups and downs. Therefore, the purpose of this essay is to acquaint the curious with the moments and figures that marked the beginning of the non-theoretical history of AI.</p>
    <img src={sciAI} alt="Image of scientist and AI" className='blog'></img>
    <h3>Father of AI</h3>
    <p>The British logician, mathematician, and cryptographer, Alan M. Turing, is often popularly referred to as the father of AI and theoretical and modern computer science.</p>
    <p>During World War II, Turing managed to crack the 'Nazi Enigma Code', thus ensured the Allied victory. By 1950, shortly after the emergence of the first computer, Turing was already motivated by the possibility that machines could think like humans. Therefore, in one of his works, he proposed conducting testing of machine intelligence, initially known as the 'imitation game', and today known as the 'Turing Test'. At that time, doubts about Turing's idea were significant. However, today we have what we call AI with all its benefits.</p>
    <h3>Birthplace of AI</h3>
    <p>In the early 1950s, AI systems had various names (cybernetics, automata theory, complex information processing), depending on the conceptual orientation. This changed in 1955, when John McCarthy, a newly appointed assistant professor of mathematics at Dartmouth College, decided to bring together a group of colleagues to delve into the realm of thinking machines. Finally, he gives their field of research a neutral name 'artificial intelligence'.</p>
    <p>In the same year, McCarthy approached the Rockefeller Foundation to request funding for a two-month workshop at Dartmouth, and soon after, the project was formally proposed by McCarthy, Marvin Minsky, Nathaniel Rochester, and Claude Shannon. The undertaking in question is 'Dartmouth Summer Research Project on Artificial Intelligence', now widely regarded as a foundational event in the field of AI.</p>
    <p>The Foundation provided only half the money they requested, and an additional complication was that it was planned for eleven mathematicians and cognitive scientists to participate, but some of them did not come, while around ten others came for short periods. Despite everything, the event was held and lasted a full nine weeks during the summer of 1956. All of it took place on the top floor of Dartmouth College, which housed the Math Department.</p>
    <p>It is important to emphasize that even though they failed in what was initially planned, namely making an intelligent algorithm per se, they achieved something greater through their participation. Most of them remain remembered as pioneers who formalized the concept of AI as a branch of science, inspiring many people to pursue AI goals in their own way.</p>


  <h3>AI Winter</h3>
  <p>Although AI summer predominates from the mid-twentieth century, bringing a significant surge of influential ideas and discoveries, some setbacks winters will also be remembered.</p>
  <p>The term 'AI winter' refers to a period of significantly reduced interest in artificial intelligence research. In this field, there have been several hype cycles, typically accompanied by difficulties such as dissatisfaction within the community, public criticism, lack of funding, and hopes for progress. Nonetheless, after each 'cooling off' period, there would be a recovery within a few years, or in the worst case, decades later.</p>
  <p>Two longer periods of winter have been recorded: the first from 1974-1980, and the next from 1987-2012. The term itself was coined in 1984 and was the subject of public debate at the annual meeting of AAAI, or the American Association for Artificial Intelligence. Experienced and highly significant researchers Roger Schank and Marvin Minsky personally faced the first period and were able to predict the next based on the preceding overly enthusiastic years. They pointed out a chain reaction, known as the 'nuclear winter'. Indeed, three years later, the second collapse occurred.</p>
  <p>Since 2012, the field of AI has been regaining strength. Now, in 2024, AI is experiencing its most glorious era, which we rightfully can call a 'boom phase' - and this time, there are hardly any signs indicating impending storms.</p>
   <h3>Conclusion</h3>
  <p>The journey through the evolution of AI, from visionary insights and its inception, through the consistent efforts of researchers invested in development and overcoming occasional downturns, paints a rich picture of human and scientific curiosity, ingenuity, and resilience. The current era
stands as evidence of our true commitment to the task of collectively transforming something that was once purely speculative into reality. With optimism, we welcome it and continue to look ahead, envisioning humanity's potential to finally evolve into its best self with such support.</p>
     </div>,
     "Date":"10.03.2024",
     "Author":"Angela Pupovac"
  },
  {
  "id":"advanced-forms-ai",
  "Title": "Advanced Forms of AI: AGI and ASI",
  "Body": <div><p>As AI rapidly advances, it has reached and even surpassed human capabilities in some domains. We're adjusting to the societal changes stemming from this progress and striving to find compromises to harness its benefits. However, the future holds much more radical innovations than, for example, AI voice assistant or self-driving cars. Indeed, AI has the great potential to fully cover a broad spectrum of applications, initially mirroring human capabilities, and eventually cover all of them, surpassing humans by far. This essay diverges from artificial narrow intelligence (ANI) and delves into key unintended consequences that could arise when futuristic forms emerge, offering brief guidelines for potential solutions.</p>
  <img src={AGIPic} alt="Image of thinking and decision making" className='blog'></img>
  <h3>Artificial General Intelligence (AGI)</h3>
  <p>AGI will be intelligence that mimics, or to some extent surpasses, human cognitive performance in many areas of interest. Thus, AGI cannot be, for example, a chess program like ‘Stockfish’, since it is intelligent only within that specific domain. By wide agreement, AGI entails the ability to perform various tasks aligned with ultimate goals across different contexts and environments. This standard will be attainable if it effectively generalizes acquired knowledge. (Goertzel 2014: 3)</p>
  <p>However, currently, limitations lie in the fact that for every real-world AI system, although it may be highly successful in solving certain tasks, there are some learning tasks on which it is unacceptably slow, so it is expected that even the application of extended AI will be somewhat limited to certain types of problems that it excels at. (Goertzel 2014: 2, 3)</p>
  <h3>AGI Hazards</h3>
  <p>There are no unambiguous predictions about when AGI will be achieved. However, there are a couple of obvious challenges that we need to address upon its emergence, so it would be responsible of us as societies to start preparing soon through information and education. The first one is already gaining strength. It concerns the potential loss of jobs for humans. There are claims that within the next 20 years, computers and robots will demonstrate their ability to competitively compete with human intelligence. When that happens, the issue of job displacement could be significant. This trouble is often justified by studies from the University of Oxford, the Organization for Economic Co-operation and Development (OECD), and PwC (formerly Price Waterhouse Coopers), who predict that employment will drop by between 9 and 38 percent from current levels. (Okechukwu Ekwueme et al. 2023: 8) If society were to pay closer attention to such warnings promptly and take them seriously, we would have enough time to adapt employee skills, ensuring that new versions of professions fit into the upcoming technological landscape. The next one concerns preserving mental health. If everything can be readily obtained without investing too much effort, enjoyment and sense of purpose may start to disappear. This is undoubtedly concerning; however, the solution can be simple. Again, we need to learn to live with the new tools. If we use them moderately, only when necessary, life can actually become easier and more joyful. As always, part of the responsibility lies with us, and we are free to determine how to use the resources available to us.</p>

<h3>Superintelligence (ASI)</h3>
<p>According to a rough definition, superintelligent systems are those that will exhibit a form of general intelligence far beyond human capacity. The capability to learn fast, deal with uncertainty and probabilistic information, as well as infer concepts from sensory input and internal states for the purpose of logical reasoning, should be integral features of the blueprint for AI systems aiming to achieve superintelligence – whether sentient or not.</p>
<p>It's possible to identify several (5) technological pathways to the emergence of Super AI: artificial intelligence, whole brain emulation, biological insight, human-machine interfaces, and networks and organizations. (Bostrom 2014: 51) It has been shown that the progress of AI is the most likely and fastest pathway because it does not necessarily require a massive advancement in technologies like the others. It is believed that it will still take a lot of time and significant effort, but theoretically, there is nothing preventing someone from understanding how to do it, sitting down, and writing the code for seed ASI on a conventional computer today. (Bostrom 2014: 71)</p>
<h3>ASI Hazards</h3>
<p>One day, in the relatively distant future, we might encounter this entity. Approximately, it's a sci-fi supercomputer - except it doesn't have to be a computer or a robot at all; it could exist in its VR or some entirely different form. It is required to employ imagination and acknowledge the possibility that some kind of poorly designed ASI, for instance, could engage in war with humans (like in the movie Terminator). Alternatively, as some sort of sage, which, fundamentally, would be in relation to us, it might choose a type of meditation and tranquility as it finds no purpose in interacting with humans to contemplate undisturbed within itself undisturbed. Or we could face a situation where it has created and prioritized independent desires and ambitions and decided to pursue them, ignoring the needs of its creators. This is a depiction of just a few undesirable consequences of launching a faulty version of SAI. In any case, since it would be too powerful, there's no certainty that there's suitable weaponry for warfare against it, so it's best that such a war never occurs. Protection should consist of prevention, i.e., careful, ethical shaping of the system in the preceding, early stages.</p>
<h3>How Are We Doing?</h3>
<p>At this juncture, such demands may appear daunting to the average reader – as it is not easily conceivable how capabilities such as speculation, functional senses, and emotions, inherently belonging to the living world, could be implemented into a machine system. However, the rise of AI could lead to an intelligence explosion. (Okechukwu Ekwueme et al. 2023: 5) Upon examining the breakthroughs launched in the past few years, we will notice that we have rapidly progressed towards realizing these scenarios. It seems that scientists have found alternative ways to meet criteria and compensate for certain conscious (and unconscious) mechanisms inherent to humans.</p>
<p>For instance, regarding the ability of prediction, the vivid empirical experience of 'familiarity' with the nature of something (surprisingly successful) replaces the tactic of gathering, sorting, and amalgamating a greater amount of information about subjects based on their (voluntary) recorded actions, employed by companies such as Google, Facebook, etc., which is aimed precisely at predicting their likely future states or future interests and needs. Moreover, among the latest discoveries, we will encounter robots that, instead of possessing a sense of smell like humans, are trained to distinguish substances by the odor they emit in a different way – along with the accompanying advanced recognition ability to discern which of them are dangerous and which are not. Then, there are autonomous trucks and other types of vehicles that, instead of using the sense of sight, successfully perform long journeys without a driver using special sensors – and that without the possibility of making mistakes that occur to human beings due to fatigue, shifting attention focus, etc. In place of emotional intelligence that enables human beings with empathic ability, LLM’s possess algorithmic intelligence at a high-level resembling cognitive and are capable of technical 'empathy' based on recognizing written content and responding appropriately to certain patterns.</p>
<h3>Conclusion</h3>
<p>Achieving describes milestone has always been the 'Holy Grail' of AI research. (Schlicht 2022: 5) Therefore, it is expected that it offers more and more opportunities. However, if we want to avail ourselves of these and have AI take on some of the problems that burden us, we must also ensure its integration into our world. With our vigilance and adaptability, as well as ensuring its quality construction, we can look forward to the desired outcome.</p>
<h4>References</h4>
<p>Goertzel, Ben (2014). „Artificial General Intelligence: Concept, State of the Art, and Future Prospects“, Journal of Artificial General Intelligence 5(1):1-46.</p>
<p>Bostrom, N. (2014). Superintelligence: Paths, dangers, strategies (Oxford: Oxford University Press).</p>
<p>Okechukwu Ekwueme, Francis, Areji C. Anthony, Ugwu, Anayochukwu. (2023). „Beyond the Fear of Artificial Intelligence and Loss of Job: a Case for Productivity and Efficiency“, Qeios 10.32388: 1-11.</p>
<p>Schlicht, Tobias (2022). „Minds, Brains, and Deep Learning: The Development of Cognitive Science Through the Lens of Kant’s Approach to Cognition“, in Hyeongjoo Kim &amp; Dieter Schönecker (ed.), Kant and Artificial Intelligence (Berlin: De Gruyter): 3-38.</p>
  </div>,
   "Date":"19.02.2024",
   "Author":"Angela Pupovac"
},
  {
    "id":"genai-decision-making",
    "Title": "Generative AI: Ally in Decision-Making",
    "Body": <div><p>People (rightfully) tend to be anxious about making decisions on a rational basis. The
    question we ask ourselves is, 'Have I thought this over long enough?' or in other words, 'Have
    I taken enough data into consideration?'. Indeed, it's very difficult to be completely sure -
    there can always be more. That's why it often happens that we just keep reconsidering until
    we run out of time, and at the moment of decision-making, we resort to snap judgments. This
    doesn't sound like the best method, right?</p>
    <img src={decMaking} alt="Image of thinking and decision making" className='blog'></img>
    <p>Now, imagine that you're not alone in this and that you have a skilled, amicable
assistant at hand for every dilemma. AI wants to become that 'when it grows up'. Not too long
ago, this would have sounded too good to be true. Until today, we've realized that there is no
concrete reason to claim that such a future cannot become a reality. However, there are many
indicators that this process of advancement will not be easy at all and we will have to make a
concerted effort to ensure success. This essay explores some of the main conditions that must
be met in order to achieve this goal.</p>
    <h3>Explainable AI (XAI)</h3>
    <p>In various domains, it is fair for decision-makers to be able to justify the reasons for
their decisions to others, so they can rightfully remain in their positions. Thus, if a decision is
made (at least partially) based on the conclusions of an AI system, it is essential for building a
trustworthy relationship between humans and AI to provide explanations of how it operates
and arrives at its results. (Ferreira & Monteiro 2021: 1) This applies whether we are
collaborators in this process or observers. If we are the ones who will bear responsibility for a
decision, it will be important for us to know the basis on which it is made. And if the decision
is made by someone else, we will have a need to understand and evaluate it if it concerns us
or the society in which we live.</p>
<p>Only AI accompanied by the attributes of transparency and interpretability is worthy
of being an (super) advisor. Aside from the availability of clear and suitable technical
explanations, for most decision-makers, it's also crucial to ensure that the way AI addresses
ethical challenges is in line with the greatest human welfare.</p>
<h3>Tech - Snapshot</h3>
<p>The first of the key criteria for establishing AI as a competent decision-making ally
is that we know it is consistently precise and has been trained on accurate, verifiable data. -
yet we still cannot truly say that this has been ensured.</p>
<p>So far, we've had the opportunity to enjoy the assistance of certain tools. We often
hear 'Ask Google,' and more recently, 'Ask ChatGPT' has become common. However, it's
difficult to gauge the extent to which these sources can provide support. While websites are
repositories of arbitrary information claimed by various authors, the unreliability of AI
generative language models manifests in occasional partially correct or even contradictory
information, i.e., hallucinations. If we rely entirely on it, the chance of making a mistake is
still non-negligible.</p>
<p>LLM's (Large Language Models) are capable of producing several convincingsounding
sentences in a row and stringing them together, but they also exhibit strange
patterns, habits, and limitations. If we had a partial understanding of the question we posed
previously, we will recognize irregularities, but the problem lies in the fact that someone who
knows nothing about the subject can be easily deceived because AI sounds confident in
providing all of its answers. All of this is happening because AI can't understand what it's
saying, so it's definitely not self-checking. It doesn't form sentences like humans do; rather, it
generates a new sentence by taking our input and then creating output based on related words
that are most likely to occur together. Additionally, another major problem lies in the fact that
the 'literature' that generative models rely on is not necessarily of the best quality.</p>
<p>It's clear that if we need an answer and then need to double-check it through further
research, we don't have an efficient assistant. However, basically, everything AI currently
does requires a human final touch.</p>
<h3>AI Moral Codex</h3>
<p>Besides technical challenges, the next big challenge on the horizon is ethical
concerns, which currently mainly revolve around privacy and bias. It's not enough for AI
conclusions to appear morally correct at first glance; it's essential that they actually are, in a
broader sense. Once more, explainability is key. We want to know and understand moral
framework of AI.</p>
<p>The problem of AI alignment concerns ensuring that AI systems align with a diverse
set of human values, encompassing moral, social, justice-oriented, and other relevant
principles (Mougan & Brand 2023: 2). The aim is to structure AI in a way that successfully
covers what is fundamentally common to all well-intentioned goals, namely aligning well
with the widely accepted (according to the Universal Declaration of Human Rights) concepts
of inalienable human rights. (Mougan & Brand 2023: 1, 5)</p>
<p>Designers/developers of AI systems are responsible for ensuring this. Moreover, they
must always be aware of all those who may be affected by their choices. The impact of AI on
society must always be a top priority and concern for those who influence theits operation.
(Ferreira & Monteiro 2021: 1)</p>
<p>Philosophy serves as a project engineer who should be consulted for such purposes.
Instead of attempting to embed a myriad of newly emerging individual instructions with
narrow applications into AI and thus naively overlook a large number of exceptions that
would lead to undesired consequences, a safer solution is to choose a previously established
and coherent value system as a 'moral database' that AI will use, and we are left to explore
which ethical set of prescribed values promises most to fulfill our requirements and
expectations. Utilitarianism is gaining popularity as an approach in the fairness metrics of
artificial intelligence. However, there are also other high-quality ethical theories, such as
Kantian ethics, whose compatibility in this field, despite the importance of deontology,
remains insufficiently explored (Mougan & Brand 2023: 1).</p>
<h3>Verif.ai Contribution to XAI</h3>
<p>As indicated, the first challenge to overcome in order to finally provide satisfactory
explanations is the fallibility of generative models. That's why we embarked on the quest for
AI system designed to verify and document the correctness of facts in AI-generated texts.</p>
<p>Humans and humanity face both minor and major decisions. Typically, we rank their
importance based on the consequences that may arise from them. Undoubtedly, everyday life
decisions could be easier to make with the right advice. However, where professional
assistance is particularly welcome is where every consequence is of paramount importance -
in science. AI can be used in almost any field, including healthcare, manufacturing, education,
finance, and more. The Verif.ai project has decided to start by addressing one of the most
crucial scientific domains: biomedicine. After all, health itself is something whose proper
functioning enables the proper functioning of everything else. When hallucinations are
successfully overcome in this field (through additional verification and the provision of
generated answers based on a relevant database), expansion into other areas follow. Step by
step, we are on the right path towards making the privileges that were described commonplace
for everyone.</p>
<h3>Promising Future</h3>
<p>The help of AI should not have a negative impact on our confidence in our abilities
because ultimately, we remain the ones making the final decision. (Ferreira & Monteiro 2021:
7) What a well-functioning XAI would provide us with is a solid foundation to start from.</p>
<p>Mutual complementarity could work well for the simple reason that AI and humans
have different strengths and resources at their disposal. Creating and analyzing should not be
attempted simultaneously; they are entirely different processes. When performed
independently, calculating can consume too much valuable time, and without a practical way
to ascertain that the decision we've made is correct, mistakes are common. Therefore, the
contribution of an assistant that would take over that part of the work would be significant. On
one hand, critical thinking and innovation are still attributes exclusive to humans; on the other
hand, AI has a compelling quantitative advantage when it comes to handling vast amounts of
data, rapid action, and multitasking. It should play the role of an assistant adept at statistics,
comparison, summarization, and data sorting. The benefits of that are noteworthy. People
could finish their tasks tranquilly, without dwelling on skeptical deliberation and also learn
faster. If it diligently performed its part, we could forge a strong alliance.</p>
<p>Often, it is the human inability to foresee consequences that hinders our decisionmaking
process. AI has a way of alleviating this fear of uncertainty, prediction is it’s
superpower. Being able to rapidly process a larger amount of information than humans ever
could, it is capable of presenting the most likely outcome of a process. We wouldn't have to
engage in painstaking guesswork, but rather further assess the situation based on its
calculations and decide whether such an outcome is desired or not. There is a spectrum of
benefits: we rid ourselves of procrastination and overthinking because we have clarity, and
instead of insufficiently considered decisions when we lack time, we have quick and readily
available assistance. Using AI in this way finally frees up enough space for us to progress at
the pace and manner we ideally desire.</p>
<h3>Conclusion</h3>
<p>The integration of AI into decision-making processes carries great potential but also
challenges. It is crucial to establish a harmonious relationship between humans and AI, where
AI serves as a reliable partner in achieving the decision maker's goals. Transparency,
interpretability, and ethical alignment are key factors for a successful alliance. Initiatives like
Verif.ai represent steps forward in overcoming technical challenges and ensuring the
reliability of decisions guided by AI. There is good reason to believe that, with the right
strategies and patience, AI can evolve into a support without second-guessing.</p>
<h4>References</h4>
<p>Mougan, Carlos, & Brand, Joshua (2023). „Kantian Deontology Meets AI Alignment:
Towards Morally Robust Fairness Metrics“, arXiv:2311.05227v1 [cs.AI]: 1-9.</p>
<p>Juliana Jansen Ferreira, and Mateus Monteiro (2021). „With great AI power comes great
responsibility: AI explanation to support people on justifying their decisions“, in
Transparency and Explanations in Smart Systems (TExSS) workshop, held in conjunction
with ACM Intelligent User Interfaces (IUI), April 13-17, 2021, virtual event: 1-9.</p>
    </div>,
     "Date":"12.02.2024",
     "Author":"Angela Pupovac"
  },
{
    "id":"public-trust",
    "Title": "Public Trust in AI",
    "Body": <div><p>This essay aims to highlight the (new) hope in the possibility of relying on AI as our main assistant, both in everyday advice and scientific purposes. This will be achieved through a brief overview of the prerequisites for trustworthy AI and LLMs, followed by how they can be met. As an example of a system that meets these criteria, the carefully equipped open generative system Verif.ai is proposed. Renowned philosopher René Descartes once reasonably asserted, 'It is prudent never to trust those who have deceived us, even if only once', however, LLMs are about to undergo a fundamental change that seems to deserve attention and consideration to truly become trustworthy.</p>
    <h3>Trust and Condition for Trust in AI</h3>
    <p>It is convenient to begin with the very definition of the concept of 'trust' and a brief
interpretation related to it. For example, according to Google's English Dictionary, trust
entails a firm belief in the reliability, truth, or ability of someone or something. Moreover, this
concept is commonly used in the context of human interpersonal relations. In today's society,
the situation can be outlined through three fundamental statements regarding trust, with which
most of us would likely agree. The first observation is that there has been a significant decline
in the levels of trust (in various individuals, organizations, institutions, etc.). The second
represents the goal of having more trust. The third is the task of renewing it. This process can
pose a significant challenge for the entire system, as it is difficult to avoid subjectivity and a
variety of opinions regarding the correct strategy to be undertaken in order to achieve better
individuals and better organization. However, the challenge concerning trust in AI
technologies, while undoubtedly substantial, is more linear and bypasses many moral
dilemmas, uncertainties and unpredictable circumstances associated with (purely) social
dealings.</p>
<p>What sets a condition for trust and enables AI to participate equally in favorable
transactions is the transparency of AI models. This transparency involves our ability to
understand how they operate and/or ability to effectively communicate with them, depending
on our needs. The foundation of these capabilities lies in the shared possession of certain
knowledge (of certain information). Hence, we can apply the traditional definition as a
criterion for its validity. It posits that knowledge, in a strict sense, should be understood as
true, justified belief. Although the prerequisite that the knowledge we share rests on such a
qualitatively sound foundation is stringent, once successfully met, trust in AI is established
and is difficult to be undermined.</p>
<h3>Main (Trust) Issue with Current LLMs</h3>
<p>Generative Large Language Models (LLMs) are currently gaining popularity as a
valuable AI tool in everyday life. However, what tarnishes their reputation are specific
shortcomings in their current functioning and, consequently, the results they produce. One of
the key and most obvious issues is the phenomenon known as hallucinations. This problem 
has not yet received a satisfactory solution, and thus, the possibility of generated responses
based on input text being incorrect remains unresolved.</p>
<p>When it comes to scientific truths, accuracy and reliability are of paramount
importance, and the possibility of hallucinations is an unacceptable failing that prevents the
acceptance of using LLMs within the scientific community. Consequently, they are subject to
suspicions from all users when complete reliance on them is necessary, and are often not the
first choice when making decisive moves and decisions. A comprehensive solution is required
to ensure the utilization of the benefits of these models without compromising the integrity of
scientific knowledge.</p>
<p>Public trust, in the context of LLMs, can only be justified if final corrections are
made, ensuring that when using these models for any purpose, users are provided with wellsupported, official, and accurate information. This expectation can largely be fulfilled by
LLMs that, for this purpose, focus on a specific high-quality database. Such a database must
be built on previously documented, original, scientific, and academic literature. The second
part concerns reliable, detailed content verification that it disseminates. The second
requirement pertains to thorough verification of the content it disseminates. Now, how to
achieve this?</p>
<h3>Verification Mission of the Verif.ai System</h3>
<p>Verif.ai is an open scientific generative question-answering system with referenced
and verifiable answers - and it promises a solution to the problem. Since it possesses the
necessary components, it is capable of searching for information by combining semantic and
lexical search techniques in scientific papers. Subsequently, it selects the most appropriate
claims related to the output and generates responses with references to the papers from which
they were derived. Furthermore, it ensures additional verification to identify any presence of
hallucinations in the generation process.</p>
<p>This system is currently designed for use in the biomedical domain, providing
answers based on scientific articles indexed in PubMed, and aims to accelerate progress in
many important fields of medical and biomedical sciences. However, Verif.ai has the great
potential to easily expand to other document formats and become the foundation for a
personal, organizational, or corporate generative search engine with reliable answers. In the
future, this may include additional sources, contributing to everyone's trust and safety on the
next generation internet.</p>
<h3>Conclusion</h3>
<p>In the days ahead, the influence of AI on people's ways of life is expected to
continuously grow, and one might fear that, given the black box nature of their mechanisms,
the risk of trust in it will also grow. (Choung et. al. 2022: 23) However, as there is a push for
fundamental structural changes, it seems that future LLMs, improved in the described manner,
deserve an entirely new, untarnished chance to become our support. The significance of this
trust then takes on a positive connotation.</p>
<h4>References</h4>
<p>Choung, Hyesun, David, Prabu, & Ross Arun (2022). 'Trust in AI and its role in the
acceptance of AI technologies'. International Journal of Human–Computer Interaction: 1–25.</p>
    </div>,
     "Date":"1.02.2024",
     "Author":"Angela Pupovac"
  },
{
    "id":"hiring",
    "Title":"We are hiring an intern!",
    "Body":<p>Verif.ai project is looking for an intern to help us fight hallucinations in large language models and help find verifiable answers for hot questions in life sciences. If you are a student enrolled at the master program in the European University, large language models inspire you and you would like to use them, and learn more about them, consider applying at <a href='https://jobs.bayer.com/job/Berlin-Intern-Machine-Learning-%28fmd%29-Berl/985837601/' target='_blank' rel='noreferrer'>https://jobs.bayer.com/job/Berlin-Intern-Machine-Learning-%28fmd%29-Berl/985837601/</a>
    <br/> You will be working in an international team of top NLP scientist from Bayer and Institute for Artificial Intelligence Research and Development in Serbia on developing verifiable generative search engine for life sciences. 
    </p>,
    "Date":"27.09.2023",
    "Author":"Nikola Milosevic"
}
];

const meta = {
  title: "Verif.ai Blog",
  description: "A Blog of Verif.ai project",
  canonical: "https://verifai-project.com/blog",
  og:{
    title:  "Verif.ai Blog",
    description: "A Blog of Verif.ai project",
    url:"https://verifai-project.com/blog",
  },
  meta: {
      charset: 'utf-8',
      name: {
          keywords: 'react,meta,document,html,tags'
      }
  }
}

function Blog() {
  useEffect(() => {
    document.title = "Verif.ai - Blog";
  }, []);
  return (
    <div className="App">
      <DocumentMeta {...meta} />
      <TopMenu/>
<hr/>
<div className='LeftFrame'></div>
        <div className='MainFrame'>
            
        <a href='/'><img src={logo} className="App-logo" alt="logo" /></a>
        
        <div className='FrontTextArticle'>
            <h1>Blog</h1>
          
            <div className="posts-container">
	{BlogPosts.map((post, index) => (
		<Post key={post.id} index={post.id} post={post} />
   
	))}
	</div>


          <br/>




          </div>

        
    </div>
    <div className='RightFrame'></div>
    <PageBottom/>
    </div>
  );
}

export default Blog;
