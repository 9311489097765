import React from "react";
import BlogPosts from './Blog.js'
const Post = ({ post: { Title, Body,
	Date, Author,id }, index }) => {
return (
	<div className="post-container">
	<a href={"/post?id="+id}><h2 className="heading">{Title}</h2></a>
	<p>{Body}</p>
	<div className="info">	
		<h4>Written by: {Author}</h4>
		<p>{Date}</p>
	</div>
	<hr/>
	</div>
);
};
export default Post;