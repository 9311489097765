import React, {Component} from 'react';
import EuroFlag from "./Flag_of_Europe.png"
import NGISearch from "./NGI_Search-logo.png"
class PageBottom extends Component {



  render() {



    return (

<div className='PageBottomMain'>
        <div className='LeftBottomDiv'></div> 
      <div className="PageBottomDiv">
        Funded by the European Union. Views and opinions expressed are however those 
        of the author(s) only and do not necessarily reflect those of the European Union or
        European Commission. Neither the European Union nor the granting authority can
        be held responsible for them.
        Funded within the framework of the NGI Search project under grant agreement
        No 101069364
        <br/>
        <div className="Sponsors">
        <a href='https://www.ngi.eu/ngi-projects/ngi-search/' target='_blank' rel="noreferrer"><img src={NGISearch} className='ngi_flag' alt="NGI Search logo"/></a>
        <img src={EuroFlag} className="flag_europe" alt="European Union flag" />
        </div>
      </div>
      <div className='RightBottomDiv'></div> 
      </div>
      
       );
      }
    }
    
    export default PageBottom;