import logo from './verifai-logo.png';
import './App.css';
import React, { useEffect }  from "react";
import PageBottom from './PageBottom';
import TopMenu from './TopMenu';
import Facebook from './facebook.png';
import Twitter from './twitter.png';
import LinkeIn from './LinkedIn.png';
import DocumentMeta from 'react-document-meta';
import Github from './github.png';
import Instagram from './instagramico.png';
import TikTok from './tiktok.png';

function About() {
  useEffect(() => {
    document.title = "Verif.ai - About";
  }, []);
  const meta = {
    title: "Verif.ai About",
    description: "About Verif.ai project",
    canonical: "https://verifai-project.com/about",
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
  }
  return (
    <div className="App">
       <DocumentMeta {...meta} />
      <TopMenu/>
<hr/>
<div className='LeftFrame'></div>
        <div className='MainFrame'>
            
        <a href='/'><img src={logo} className="App-logo" alt="logo" /></a>
        
        <div className='FrontTextArticle'>
            <h1>About</h1>
          <strong>VerifAI</strong> is an open-source biomedical generative question-answering engine. However, on top of being able to answer biomedical questions based on indexed scientific publication, it will also reference relevant documents, and verify answers whether claims made in them are fully based on the referenced documents and detect any hallucinations produced by underlaying large language model (our method is based on fine-tuned Mistral 7B).
          <br/>

          <br/>
          VerifAI is an AI system designed to verify and document the correctness of AI-generated texts. In the core of the engine is generative search engine, 
          powered by open technologies. However, generative models may halucinate, and therefore Verif.ai is developing a second model that would check the 
          sources of generative model and flag any misinformation or misinterpretations of source documents. Therefore, make the answer created by generative 
          search engine completly verifiable. The best part is, that we are making it open source, so anyone can use it!

          <h2>Team</h2>

        Our team is composed of NLP experts from Bayer Pharma R&D Data Sciences and AI department, and NLP group members from the Institute for AI Research and Development of Serbia.
        Current team setup is: 
        <ul>
            <li>Nikola Milosevic - Bayer Pharma R&D Data Sciences and AI, Team lead</li>
            <li>Lorenzo Cassano - Bayer Pharma R&D Data Sciences and AI, Team member</li>
            <li>Adela Ljajić - Institute for Artificial Intelligence Research and Development of Serbia, Team member</li>
            <li>Miloš Košprdić - Institute for Artificial Intelligence Research and Development of Serbia, Team member</li>
            <li>Bojana Bašaragin - Institute for Artificial Intelligence Research and Development of Serbia, Team member</li>
            <li>Darija Medvecki - Institute for Artificial Intelligence Research and Development of Serbia, Team member</li>
            <li>Angela Pupovac - Institute for Artificial Intelligence Research and Development of Serbia, Team member</li>
        </ul>

        <h2>Publications</h2>
        <ul>
        <li><a href="https://arxiv.org/pdf/2407.11485" target='_blank'>Adela Ljajić, Miloš Košprdić, Bojana Bašaragin, Darija Medvecki, Lorenzo Cassano, Nikola Milošević, “Scientific QA System with Verifiable Answers”, The 6th International Open Search Symposium 2024</a></li>
          <li><a href="https://arxiv.org/pdf/2402.18589.pdf" target='_blank'>Košprdić, M., Ljajić, A., Bašaragin, B., Medvecki, D., & Milošević, N.  "Verif. ai: Towards an Open-Source Scientific Generative Question-Answering System with Referenced and Verifiable Answers." The Sixteenth International Conference on Evolving Internet INTERNET 2024 (2024).</a></li>
          <li><a href='https://arxiv.org/abs/2407.05015' target='_blank'>Bojana Bašaragin, Adela Ljajić, Darija Medvecki, Lorenzo Cassano, Miloš Košprdić, Nikola Milošević "How do you know that? Teaching Generative Language Models to Reference Answers to Biomedical Questions", Accepted at BioNLP 2024, Colocated with ACL 2024</a></li>
        </ul>

        <h2>Funding</h2>

        VerifAI is NGI Search project funded by NGI Search scheme of the European Union under grant agreement No 101069364.

        <h2>Commercial use and consulting</h2>

        In case, you would like us to consult on deploying solution based on Verif.ai or would like to use the developed solution under different licensing 
        terms then provided by default, please get in touch at <a href="mailto:verif.ai.project@gmail.com">verif.ai.project[at]gmail.com</a>

        <h2>Social media</h2>
        <div className="SocialMedia">
        <a href='https://www.facebook.com/profile.php?id=61550906685787' target='_blank' rel="noreferrer"><img src={Facebook} className='soc_media' alt="Facebook page"/></a>
        <a href='https://twitter.com/verif_ai' target='_blank' rel="noreferrer"><img src={Twitter} className="soc_media" alt="X.com page" /></a>
        <a href='https://www.linkedin.com/company/verif-ai-project' target='_blank' rel="noreferrer"><img src={LinkeIn} className="soc_media" alt="LinkedIn page" /></a>
        <a href='https://www.instagram.com/verif.ai_project/' target='_blank' rel="noreferrer"><img src={Instagram} className="soc_media2" alt="Instagram page" /></a>
        <a href='https://www.tiktok.com/@verif.ai?_t=8kTqxv5uUp3&_r=1' target='_blank' rel="noreferrer"><img src={TikTok} className="soc_media2" alt="TikTok page" /></a>
        <a href='https://github.com/nikolamilosevic86/verif.ai' target='_blank' rel="noreferrer"><img src={Github} className="soc_media" alt="Github repository" /></a>
        </div>


          <br/>
          <br/><br/>
          <br/>



          </div>

        
    </div>
    <div className='RightFrame'></div>
    <PageBottom/>
    </div>
  );
}

export default About;
