import React, {Component} from 'react';
import bayerLogo from './Logo_Bayer_small.png';
import ivi_eng from './Ivi_eng.jpg'

class TopMenu extends Component {


  render() {



    return (

      <div className="TopMenu">
        <div className="LeftSide">
        <a href='https://www.bayer.com/en/' target='_blank' rel="noreferrer"><img className="top_logo_b" src={bayerLogo}/></a>
          <a href='https://www.ivi.ac.rs/' target='_blank' rel="noreferrer"><img className='top_logo_i' src={ivi_eng}/></a>
        </div>
        
       
        <div className="RightSide"><a className="MenuLink" href="/contact">Contact</a></div>
        <div className="RightSide"><a className="MenuLink" href="/about">About</a></div>
        <div className="RightSide"><a className="MenuLink" href="/blog">Blog</a></div>
        <div className="RightSide"><a className="MenuLink" href="https://app.verifai-project.com">Sing in!</a></div>
        <div className="RightSide"><a className="MenuLink" href="/">Home</a></div>
        

{/*
        <div className="RightSide">
         <div className="dropdown">
  <a className="dropbtn">Named entity recognition</a>
  <div className="dropdown-content">
  <a className="MenuLink" href="/annotatePDF">Linnaeus for PDF</a>
  <a className="MenuLink" href="/annotateText">Linnaeus for text</a>
  <a className="MenuLink" href="/UMLS_annotate">Spacy UMLS annotator</a>
  <a className="MenuLink" href="/CRFNER_annotate">CRF annotator for compounds, genes and diseases</a>
  <a className="MenuLink" href="/CRFUMLS_annotate">CRF annotator with UMLS normalizer for compounds, genes and diseases</a>
  <a className="MenuLink" href="/MZ_annotate">Deep learning based NER (Marinika Zitnik)</a>
  <a className="MenuLink" href="/i2b2_Drug">Drug administration info (BERT-based)</a>
  <a className="MenuLink" href="/Chem_biobert">Chemicals and Drug NER (BioBERT)</a>
  <a className="MenuLink" href="/Gene_biobert">Gene NER (BioBERT)</a>
  <a className="MenuLink" href="/Disease_biobert">Disease NER (BioBERT)</a>
  <a className="MenuLink" href="/Ontonotes_ner">Ontonotes 18 entity types (Transformer-based)</a>
  <a className="MenuLink" href="/zero_shot_ner">Zero Shot NER (flair)</a>
  <a className="MenuLink" href="/ZeroShotBioNER">Zero Shot BioNER (Bayer+SIAI)</a>
  <a className="MenuLink" href="/RWE_NER">Real World Evidence Database NER</a>


  
  </div>
  
  
  </div>    */}
  



 




</div>
   
      
       );
      }
    }
    
    export default TopMenu;