import logo from './verifai-logo.png';
import './App.css';
import PageBottom from './PageBottom';
import TopMenu from './TopMenu';
import Facebook from './facebook.png';
import Twitter from './twitter.png';
import LinkeIn from './LinkedIn.png';
import Github from './github.png';
import Instagram from './instagramico.png';
import TikTok from './tiktok.png';
import VerifAIDemo from './verifAIDemo.gif';

function HomeScreen() {
  return (
    <div className="App">
      <TopMenu/>
<hr/>
<div className='LeftFrame'></div>
        <div className='MainFrame'>
        <a href='/'><img src={logo} className="App-logo" alt="logo" /></a>
        <div className='FrontText'>
          <strong>No more searches, just verifiable answers!</strong> <br/><br/>
          <div className='leftus'>
          VerifAI is a biomedical generative question-answering system with referenced and verified answers. <br/>
          The aim of the project is to provide open question-answering platform in life sciences addressing hallucination and trust in large language models. The developed tool provides several features for easier verification and validation of LLM answers. 
          
<br/>
<div className='join_button'>
<a href="https://app.verifai-project.com" className='no'><button class="button-55" role="button">Try VerifAI App Now!</button></a>

</div>
<br/>
<img src={VerifAIDemo} alt='Demo' className='blog'/>
          <h2>Publications</h2>
        <ul>
          <li><a href="https://arxiv.org/pdf/2407.11485" target='_blank'>Adela Ljajić, Miloš Košprdić, Bojana Bašaragin, Darija Medvecki, Lorenzo Cassano, Nikola Milošević, “Scientific QA System with Verifiable Answers”, The 6th International Open Search Symposium 2024</a></li>
          <li><a href="https://arxiv.org/pdf/2402.18589.pdf" target='_blank'>Košprdić, M., Ljajić, A., Bašaragin, B., Medvecki, D., & Milošević, N.  "Verif. ai: Towards an Open-Source Scientific Generative Question-Answering System with Referenced and Verifiable Answers." The Sixteenth International Conference on Evolving Internet INTERNET 2024 (2024).</a></li>
          <li><a href='https://arxiv.org/abs/2407.05015' target='_blank'>Bojana Bašaragin, Adela Ljajić, Darija Medvecki, Lorenzo Cassano, Miloš Košprdić, Nikola Milošević "How do you know that? Teaching Generative Language Models to Reference Answers to Biomedical Questions", Accepted at BioNLP 2024, Colocated with ACL 2024</a></li>
        </ul>
          <br/>
          <br/>
          <div id="mc_embed_shell2" style={{width:"16em"}}>
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n        #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 300px;}\n        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n           We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
  <div id="mc_embed_signup">
    <form action="https://gmail.us13.list-manage.com/subscribe/post?u=34c8308d0ab65e7b96e0dc5dc&id=330c16a5c6&f_id=00a105e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" >
      <div id="mc_embed_signup_scroll"><h3>Subscribe to our Newsletter</h3>
        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
        <div className="mc-field-group"><label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label><input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required defaultValue="" style={{width:"16em"}} /></div><div className="mc-field-group"><label htmlFor="mce-FNAME">First Name </label><input type="text" name="FNAME" className=" text" id="mce-FNAME" defaultValue="" style={{width:"16em"}} /></div><div className="mc-field-group"><label htmlFor="mce-LNAME">Last Name </label><input type="text" name="LNAME" className=" text" id="mce-LNAME" defaultValue="" style={{width:"16em"}} /></div>
        <div id="mce-responses" className="clear foot">
          <div className="response" id="mce-error-response" style={{display: 'none'}} />
          <div className="response" id="mce-success-response" style={{display: 'none'}} />
        </div>
        <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
          /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
          <input type="text" name="b_34c8308d0ab65e7b96e0dc5dc_330c16a5c6" tabIndex={-1} defaultValue />
        </div>
        <div className="optionalParent">
          <div className="clear foot">
            <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" defaultValue="Subscribe" />
            <p style={{margin: '0px auto'}}><a href="http://eepurl.com/iAxqpI" title="Mailchimp - email marketing made easy and fun"><span style={{display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px'}}><img className="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center'}} /></span></a></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

         <strong>Follow us on social media: </strong>
          <div className="SocialMedia2">
        <a href='https://www.facebook.com/profile.php?id=61550906685787' target='_blank' rel="noreferrer"><img src={Facebook} className='soc_media2' alt="Facebook page"/></a>
        <a href='https://twitter.com/verifai_system' target='_blank' rel="noreferrer"><img src={Twitter} className="soc_media2" alt="X.com page" /></a>
        <a href='https://www.linkedin.com/company/verif-ai-project' target='_blank' rel="noreferrer"><img src={LinkeIn} className="soc_media2" alt="LinkedIn page" /></a>
        <a href='https://www.instagram.com/verifai_system/' target='_blank' rel="noreferrer"><img src={Instagram} className="soc_media2" alt="Instagram page" /></a>
        <a href='https://www.tiktok.com/@verif.ai?_t=8kTqxv5uUp3&_r=1' target='_blank' rel="noreferrer"><img src={TikTok} className="soc_media2" alt="TikTok page" /></a>
        <a href='https://github.com/nikolamilosevic86/verif.ai' target='_blank' rel="noreferrer"><img src={Github} className="soc_media2" alt="Github repository" /></a>
        </div>
          
          <br/>
          <br/>

          </div>

          </div>

        
    </div>
    <div className='RightFrame'></div>
    <PageBottom/>
    </div>
  );
}

export default HomeScreen;
