import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import './App.css';
import HomeScreen from './HomeScreen.js';
import About from './About';
import Contact from './Contact.js';
import Blog from './Blog.js'
import SinglePost from './SinglePost';




class App extends Component {

   componentDidMount() {
  }

  render() {

    return (
      <main>
        <BrowserRouter>
        <Switch>
        <Route path="/" component={HomeScreen} exact />
        <Route path="/about" component={About} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/blog" component={Blog} exact />
        <Route path="/post" component={SinglePost} exact />

          
        {/*  <Route path="/contact" component={Contact} exact />
          <Route path="/about" component={About} exact />
    <Route path="/blog" component={Blog} exact />*/}
          

          
        </Switch>
        </BrowserRouter>
      </main>

    );
  }
}

export default App;
